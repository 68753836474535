.dialog__overlay {
	-webkit-transition-duration: 0.4s;
	transition-duration: 0.4s;
}

.dialog.dialog--open .dialog__content,
.dialog.dialog--close .dialog__content {
	-webkit-animation-duration: 0.4s;
	animation-duration: 0.4s;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
}

.dialog.dialog--open .dialog__content {
	-webkit-animation-name: anim-open;
	animation-name: anim-open;
	-webkit-animation-timing-function: cubic-bezier(0.6,0,0.4,1);
	animation-timing-function: cubic-bezier(0.6,0,0.4,1);
}

.dialog.dialog--close .dialog__content {
	//-webkit-animation-name: anim-close;
	//animation-name: anim-close;
}

@-webkit-keyframes anim-open {
	0% { opacity: 0; -webkit-transform: scale3d(0, 0, 1); }
	100% { opacity: 1; -webkit-transform: scale3d(1, 1, 1); }
}

@keyframes anim-open {
	0% { opacity: 0; -webkit-transform: scale3d(0, 0, 1); transform: scale3d(0, 0, 1); }
	100% { opacity: 1; -webkit-transform: scale3d(1, 1, 1); transform: scale3d(1, 1, 1); }
}

@-webkit-keyframes anim-close {
	0% { opacity: 1; }
	100% { opacity: 0; -webkit-transform: scale3d(0.5, 0.5, 1); }
}

@keyframes anim-close {
	0% { opacity: 1; }
	100% { opacity: 0; -webkit-transform: scale3d(0.5, 0.5, 1); transform: scale3d(0.5, 0.5, 1); }
}
