.listing_contact {
	width: calc(100% / 3 * 2);
	margin: auto;

	@include breakpoint(large down) {
		width: 100%;
	}

	.agence_block {

		@include breakpoint(large up) {
			@include grid-column(4, $gutters);

			&:last-child:not(:first-child) {
				float: left;
			}
		}

		@include breakpoint(medium down) {
			margin-bottom: 60px;
		}

		.titre {
			font-size: 19px;
			color: $white;
			font-weight: 700;
			padding-bottom: 17px;
			position: relative;
			margin-bottom: 15px;

			@include breakpoint(medium down) {
				font-size: 18px;
			}

			&:before {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				height: 4px;
				width: 27px;
				background: $green;
			}
		}

		.adresse {
			margin-bottom: 15px;

			p {
				font-size: 14px;
				color: $blueLight;
				line-height: 1.4;

				@include breakpoint(medium down) {
					font-size: 16px;
				}
			}
		}

		.telephone,
		.mail {
			color: $white;
			font-size: 14px;
			line-height: 1.4;

			@include breakpoint(medium down) {
				font-size: 16px;
			}
		}

		.mail a {
			color: $white;
		}
	}

	.rejoignez_nous_lien {

		@include breakpoint(large up) {
			@include grid-column(4, $gutters);
		}

		a {
			font-size: 19px;
			color: $white;
			font-weight: 700;
			padding-right: 26px;
			position: relative;

			&:before,
			&:after {
				content: "";
				position: absolute;
				top: 2px;
				bottom: 0;
				right: 0;
				width: 15px;
				@include transition(0.3s);
			}

			&:before {
				background: url(../img/arrow_white.png) right center no-repeat;
				background-size: 15px auto;
			}

			&:after {
				background: url(../img/arrow_green.png) right center no-repeat;
				background-size: 15px auto;
				opacity: 0;
			}

			&:hover {
				color: $green;

				&:before {
					opacity: 0;
				}

				&:after {
					opacity: 1;
				}
			}

			@include breakpoint(medium down) {
				padding-right: 37px;
				background-size: 22px auto;
				background-position: right bottom 4px;
			}
		}
	}
}