// Import setup

@import 'foundation';

@import "setup/normalize.scss";
@import "setup/variables.scss";

@import "setup/mixins.scss";
@import "setup/mixins/clearfix.scss";
@import "setup/mixins/triangle.scss";
@import "setup/grid.scss"; 

@import "setup/cssowl.scss"; 
@import "setup/sprites/global.scss";
@import "setup/sprites/picto.scss";
@import "setup/sprites/share.scss";


@import "fonts.css"; 


// Import componants 

@import "componants/animate.scss";
@import "componants/owlcarousel/owl.carousel.scss";
@import "componants/dialog/dialog.scss";
@import "componants/mapbox.scss";
@import "componants/flexslider.scss";
@import "componants/font-awesome/font-awesome.scss";

// Import elements

@import "elements/forms.scss";
@import "elements/rte.scss";
@import "elements/messages.scss";
@import "elements/dialog.scss";
@import "elements/button.scss";
@import "elements/hamburger.scss";
@import "elements/galerie.scss";
@import "elements/popups.scss";
@import "elements/node_top.scss";

// Import main files
@import "global.scss";
@import "header.scss";
@import "footer.scss";
@import "home.scss";
@import "page.scss";
@import "forms.scss";


/*  SECTIONS */


/* PAGES */
@import "pages/page-equipe.scss";
@import "pages/page-annonces.scss";
@import "pages/page-projets.scss";



/*BLOCKS */

/*@import "blocks/XXXX.scss";*/


/* LISTS */
@import "lists/list-selection.scss";
@import "lists/list-contact.scss";
@import "lists/list-actualites.scss";
@import "lists/list-global.scss";
@import "lists/list-membres.scss";
@import "lists/list-annonces.scss";
@import "lists/list-projets.scss";
@import "lists/list-books.scss";

/* NODES */
@import "nodes/actualite.scss";
@import "nodes/agence.scss";
@import "nodes/projet.scss";
@import "nodes/coworking.scss";



@import "print.scss";

