#listing_actualites {
  padding-bottom: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;

  @include breakpoint(medium down) {
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }
  @include breakpoint(small down) {
    grid-gap: 20px;
  }
  .grid_sizer,
  .gutter_sizer {
    display: none;
  }

  // .grid_sizer {
  // 	width: 48%;

  // 	@include breakpoint(small down) {
  // 		width: 100%;
  // 	}
  // }

  // .gutter_sizer {
  // 	width: 4%;

  // 	@include breakpoint(small down) {
  // 		width: 0%;
  // 	}
  // }

  .actualite_item {
    // margin-bottom: 35px;
    font-size: 0;
    // width: 48%;

    @include breakpoint(large down) {
      margin-bottom: 30px;
    }

    @include breakpoint(small down) {
      width: 100%;
      margin-bottom: 25px;
    }

    .image_wrap {
      margin-bottom: 20px;
    }

    .titre {
      margin-bottom: 10px;
    }

    .date {
      margin-bottom: 10px;
      font-size: 14px;
      color: $blue;
    }

    .texte {
      font-size: 13px;
      font-weight: 300;
      line-height: 1.4;
      color: $blackText;

      .voir_plus {
        color: $blue;
        font-weight: 700;
        @include transition(0.3s);

        &:hover {
          color: $green;
        }
      }
    }
  }
}
