#header {
	position: relative;
	z-index: 999;
	@include transition(0.5s);

	#header_main {
		height: 88px;
		line-height: 87px;


		@include breakpoint(medium down) {
			height: 66px;
			line-height: 65px;
		}

		#header_logo {
			@include grid-column(2, $gutters);
			font-size: 0;
			padding-left: 30px;
			position: relative;

			@include breakpoint(medium down) {
				@include grid-column(6, $gutters);
			}

			a {
				position: relative;
				display: inline-block;

				img {
					height: 50px;
					width: auto;
					vertical-align: middle;
					@include transition(0.5s);

					@include breakpoint(medium down) {
						height: 34px;
					}

					&.logo_contact {
						opacity: 0;
						position: absolute;
						left: 0;
						top: 0;
						bottom: 0;
						margin: auto;
					}
				}
			}
		}

		#header_menu {
			@include grid-column(8, $gutters);
			text-align: center;
			font-size: 0;

			@include breakpoint(medium down) {
				@include grid-column(12, $gutters);
				float: none;
				text-align: center;
			}

			li {
				display: inline-block;
				vertical-align: middle;

				&:not(:last-child) {
					margin-right: 38px;
				}

				a,
				span {
					display: block;
					font-size: 18px;
					font-weight: 300;
					position: relative;
					padding: 0 6px;

					&.green {
						color: $green;
						font-weight: 500;
					}

					&:after {
						content: "";
						position: absolute;
						bottom: 0;
						left: 0;
						right: 0;
						height:	3px;
						@include transition(0.3s);
					}

					@include breakpoint(medium down) {
						display: inline-block;

						&.agence {
							position: relative;

							&:before {
								content: "";
								position: absolute;
								top: 0;
								bottom: 0;
								right: -8px;
								width: 8px;
								height: 5px;
								margin: auto;
								background: url(../img/arrow_mobile_menu.png) center no-repeat;
								background-size: 100% 100%;
								@include transition(0.5s);
							}

							&.active:before {
								@include rotate(180deg);
							}

							&+ .menu {
								height: 0;
								overflow: hidden;
								pointer-events: all;
								@include transition(0.5s);								

								&.show {
									height: 138px;
								}
							}
						}
					}
				}

				> ul {
					position: absolute;
					top: 87px;
					left: 0;
					right: 0;
					text-align: center;
					@include transition(0.3s);
					z-index: 1;

					li {
						min-width: 75px;
					}

					a,
					span {

						&:after {
							content: none;
						}

						&.active,
						&:hover {
							font-weight: 700;
						}
					}

					@include breakpoint(large down) {
						line-height: 60px;
					}

					@include breakpoint(medium down) {
						position: static;
						opacity: 1;
						line-height: 40px;

						a {
							font-size: 16px;
						}
					}
				}

				@include breakpoint(large up) {

					> ul {						
						pointer-events: none;
						opacity: 0;
					}

					&:hover {

						> ul {
							opacity: 1;
							pointer-events: all;
						}
					}

					&:hover,
					&.active-trail {

						> a:after,
						> span:after {
							background: $green;
						}
					}
				}
			}
		}

		#header_contact {
			@include grid-column(2, $gutters);
			text-align: right;
			padding-right: 30px;

			@include breakpoint(medium down) {
				@include grid-column(12, $gutters);
				float: none !important;
				text-align: center;
			}

			.lien_wrap {
				display: inline-block;

				a {
					font-weight: 700;
					font-size: 18px;
					display: block;
					position: relative;
					@include transition(0.5s);

					&:before {
						content: "";
						position: absolute;
						width: 14px;
						height: 14px;
						background: url(../img/picto_close.png) center no-repeat;
						background-size: 100% 100%;
						opacity: 0;
						right: -20px;
						top: 0;
						bottom: 0;
						margin: auto;
						@include transition(0.5s);
					}
				}
			}
		}
	}

	#contact_area {
		position: absolute;
		left: 0;
		right: 0;
		top: 87px;
		background: $blue;
		border-top: 1px solid rgba(255, 255, 255, 0.5);
		padding: 70px 0;
		z-index: 2;
		@include transition(0.5s);
		opacity: 0;
		pointer-events: none;

		@include breakpoint(medium down) {
			top: 65px;
			padding: 45px 20px;
		}
	}

	&.contact_active {
		background: $blue;

		@include breakpoint(large up) {

			#header_main #header_contact .lien_wrap a {
				padding-right: 20px;

				&:before {
					opacity: 1;
					right: 0;
				}
			}
		}

		#contact_area {
			opacity: 1;			
			pointer-events: all;
		}
	}

	#burger_wrap {			
		@include grid-column(6, $gutters);
		text-align: right;

		@include breakpoint(large up) {
			display: none;
		}
	}

	@include breakpoint(medium down) {

		#header_toggled {
			clear: both;
			line-height: 60px;
			opacity: 0;
			padding: 20px 0;
			@include transition(0.5s);
			pointer-events: none;
			border-bottom: 1px solid $otherGreyBorder;

			#header_menu li {
				display: block;

				&:not(:last-child) {
					margin-right: 0;
				}
			}
		}

		&.mobile_menu #header_toggled {
			opacity: 1;
			pointer-events: all;
		}
	}

	&.front {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		background: rgba(0, 0, 0, 0.3);

		#header_main #header_menu li > ul {

			@include breakpoint(large up) {
				background: rgba(0, 0, 0, 0.3);
			}

			> li > a:hover {
				color: $green;
			}
		}

		#header_menu li {

			a:not(.green),
			span:not(.green) {
				color: $white;
			}

			> ul {
				border-top: 1px solid rgba(255, 255, 255, 0.5);

				@include breakpoint(medium down) {
					border: 0;
				}
			}
		}

		#header_contact a {
			color: $white;
		}

		@include breakpoint(medium down) {

			&.mobile_menu {
				background: $blue;
			}

			#header_toggled {
				background: $blue;
				border-top: 1px solid rgba(255, 255, 255, 0.5);
			}
		}

		@include breakpoint(medium down) {

			#header_main #header_menu li a.agence:before {
				background: url(../img/arrow_mobile_menu_white.png) center no-repeat;
				background-size: 100% 100%;
			}
		}
	}

	&:not(.front) {

		#header_main {			
			border-bottom: 1px solid $otherGreyBorder;
		}

		#header_menu li a:not(.green) {
			color: $blue;
		}

		#header_contact a {
			color: $blue;
		}

		#header_main #header_menu li > ul {
			background: $white;
			border-top: 1px solid $otherGreyBorder;
			border-bottom: 1px solid $otherGreyBorder;

			@include breakpoint(medium down) {
				border: 0;
			}
		}

		@include breakpoint(medium down) {

			#header_toggled {
				background: $white;
				border-top: 1px solid $otherGreyBorder;
			}
		}

		&.contact_active #header_main {

			#burger,
			#burger:before {
				background: $white;
			}

			#header_logo a img {
				opacity: 0;

				&.logo_contact {
					opacity: 1;
				}
			}

			#header_menu li a,
			#header_menu li span,
			#header_contact .lien_wrap a {
				color: $white;
			}
		}
	}
}



