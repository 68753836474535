#page.projets {
	padding-left: 30px;
	padding-right: 30px;
	min-height: 100vh;
	position: relative;

	@include breakpoint(large down) {
		padding-left: 25px;
		padding-right: 25px;
	}

	@include breakpoint(medium down) {
		padding-left: 20px;
		padding-right: 20px;
		padding-top: 0 !important;

		.mobile_wrap {
			height: 0;
			overflow: hidden;
			@include transition(0.5s);

			.mobile_inner {
				padding-bottom: 30px;
			}
		}
	}

	#projets_left {
		float: left;
		width: 25%;
		min-height: 1px;

		@include breakpoint(medium down) {
			width: 100%;
			float: none;
		}

		.left_scroll {
			width: 100%;
			height: 100vh;
			padding-top: 35px;

			@include breakpoint(medium down) {
				height: auto;
				padding-top: 0;
			}

			.left_inner {
				width: 150px;
				max-width: 100%;
				margin: auto;

				@include breakpoint(large down) {
					margin-left: 45px;
				}

				@include breakpoint(medium down) {
					width: 100%;
					padding-right: 0;
					margin-left: 0;
				}

				h2 {
					font-size: 23px;
					font-weight: 300;
					margin-bottom: 42px;
					position: relative;
					text-transform: none;

					&:before {
						content: "";
						height: 4px;
						width: 29px;
						background: $green;
						left: 0;
						top: 40px;
						position: absolute;
						display: none;
					}

					#burger_filters_wrapper {
						display: none;
					}

					@include breakpoint(medium down) {
						margin: 0 -20px;
						padding: 0 20px;
						margin-bottom: 30px;
						height: 60px;
						line-height: 60px;
						font-size: 16px;
						border-bottom: 1px solid $greyBorder;
						position: relative;

						&:before {
							content: none;
						}

						#burger_filters_wrapper {
							display: block;
							position: absolute;
							top: 0;
							right: 15px;
							bottom: 0;
							margin: auto;
						}
					}
				}

				#categories_projets {

					.views-exposed-widget {
						margin-bottom: 45px;

						input {
							display: none;

							&+ label {
								color: $black;
								font-size: 15px;
								display: inline-block;
								line-height: 1.4;
								cursor: pointer;
								@include transition(0.3s);
								letter-spacing: 0.5px;
								position: relative;
								text-transform: uppercase;

								&:before {
									content: "";
									position: absolute;
									width: 5px;
									height: 5px;
									background: $green;
									left: -13px;
									top: 8px;
									opacity: 0;
									border-radius: 50%;
									@include transition(0.3s);
								}

								&:hover {
									color: $green;
								}
							}

							&:checked + label {
								color: $green;
								font-weight: 700;

								&:before {
									opacity: 1;
								}
							}
						}

						&.views-widget-filter-field_categorie_projet_tid .form-item:first-child input + label {
							font-weight: 700;
							margin-bottom: 20px;
						}

						&.views-widget-filter-field_etat_du_projet_tid .form-item:first-child {
							display: none;
						}
					}

					.views-submit-button {
						display: none;
					}
				}

				.books_pdf_lien {

					a {
						@include downloadButton;
					}
				}
			}
		}

		@include breakpoint(large up) {

			&.fixed .left_scroll {
				position: fixed !important;
				left: 0;
				bottom: 0;
				width: 25%;
				padding-top: 64px;
				padding-left: 45px;
			}

			&.bottom .left_scroll {
				position: absolute !important;
				bottom: 0;
				left: 0;
				padding-top: 64px;
				width: 25%;
				padding-left: 45px;
			}
		}

		@include breakpoint(large only) {

			&.fixed .left_scroll {
				padding-left: 25px;
			}

			&.bottom .left_scroll {
				padding-left: 25px;
			}
		}
	}

	#projets_right {
		float: left;
		width: 75%;
		min-height: 1px;

		.no_result {
			margin-bottom: 60px;
		}

		@include breakpoint(large up) {

			.no_result {
				width: 70%;
			}
		}

		@include breakpoint(medium down) {
			width: 100%;
			float: none;
		}
	}
}