#page.node_page_books {

	@include breakpoint(medium up) {
		padding-top: 75px;
	}

	h1 {
		position: relative;
		padding-bottom: 15px;
		margin-bottom: 46px;

		&:after {
			content: "";
			position: absolute;
			left: 0;
			bottom: 0;
			width: 38px;
			height: 4px;
			background: $green;
		}
	}
}

ul.list_books {
	@include row(-15px);
	pointer-events: none;

	@include breakpoint(medium up) {
		margin-bottom: 70px;
	}

	> li {
		@include grid-column(4, $gutters);
		margin-bottom: 30px;
		pointer-events: all;
		@include transition(0.3s);

		@include breakpoint(medium down) {
			width: 50%;
		}

		@include breakpoint(small down) {
			width: 100%;
		}

		&:last-child:not(:first-child) {
			float: left;
		}

		a {
			display: block;
			font-size: 0;

			img {
				max-width: 100%;
				height: auto;
			}

			.title {
				display: block;
				line-height: 43px;
				border: 1px solid $otherGreyBorder;
				padding-left: 25px;
				padding-right: 60px;
				position: relative;
				width: 270px;
				max-width: 100%;
				margin: auto;
				margin-top: -23px;
				background: $white;

				&:after {
					content: "";
					position: absolute;
					top: -1px;
					right: -1px;
					height: 45px;
					width: 45px;
					background: url(../img/download.png) center no-repeat $blue;
					background-size: 13px auto;
					@include transition(0.3s);
				}

				span {
					display: block;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					color: $black;
					font-size: 18px;
				}

				&:hover:after {
					opacity: 0.9;
				}
			}
		}
	}

	&:hover > li:not(:hover) {
		opacity: 0.7;
	}
}