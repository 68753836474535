@mixin downloadButton() {
	display: block;
	border: 1px solid $blue;
	padding-left: 25px;
	height: 42px;
	line-height: 40px;
	color: $blue;
	position: relative;
	padding-right: 50px;

	&:before,
	&:after {
		content: "";
		position: absolute;
		width: 16px;
		right: 20px;
		top: 0;
		bottom: 0;
		@include transition(0.3s);
	}

	&:before {
		background: url(../img/picto_download.png) center no-repeat;
		background-size: 100% auto;
	}

	&:after {
		background: url(../img/picto_download_white.png) center no-repeat;
		background-size: 100% auto;
		opacity: 0;
	}

	&:hover {
		background: $blue;
		color: $white;

		&:before {
			opacity: 0;
		}

		&:after {
			opacity: 1;
		}
	}
}

@mixin cta() {
	display: inline-block;
	font-size: 15px;
	line-height: 22px;
	color: $blue;
	border: 1px solid $blue;
	padding: 9px 27px;
	background: $white;
	@include transition(0.3s);

	&:hover {
		background: $blue;
		color: $white;
	}
}