#section_galerie:not(.projet) {
	border-top: 1px solid $otherGreyBorder;
	padding: 50px 0;

	h2 {
		margin-bottom: 50px;
		font-size: 24px;
	}
}

#galerie_photos {

	.grid_sizer {
		width: 32%;

		@include breakpoint(medium down) {
			width: 48%;
		}
	}

	.gutter_sizer {
		width: 2%;

		@include breakpoint(medium down) {
			width: 4%;
		}
	}

	.galerie_item {
		margin-bottom: 25px;
		font-size: 0;
		width: 32%;
		cursor: pointer;

		@include breakpoint(large down) {
			margin-bottom: 20px;
		}

		@include breakpoint(medium down) {
			width: 48%;
			margin-bottom: 25px;
		}

		@include breakpoint(small down) {
			margin-bottom: 15px;
		}

		img {
			width: 100%;
			height: auto;
			@include transition(0.3s);

			&:hover {
				opacity: 0.7;
			}
		}
	}
}

#slider_galerie {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    border: 0;
    opacity: 0;
    z-index: 9999;
    background: $white;
    @include transition(0.5s);

    &:not(.show) {
    	pointer-events: none;
    }

    &.show {
    	opacity: 1;
    }

    #close_galerie {
    	width: 47px;
	    height: 47px;
	    background: $white;
	    border-radius: 50%;
	    position: absolute;
	    z-index: 3;
	    top: 30px;
	    right: 30px;
	    cursor: pointer;
	    @include transition(0.3s);

	    &:before,
	    &:after {
	    	content: "";
	    	position: absolute;
	    	top: 0;
	    	bottom: 0;
	    	right: 0;
	    	left: 0;
	    	@include transition(0.3s);
	    }

	    &:before {	    	
	    	background: url(../img/picto_close_blue.png) center no-repeat;
	    	background-size: 14px 14px;
	    }

	    &:after {	    	
	    	background: url(../img/picto_close.png) center no-repeat;
	    	background-size: 14px 14px;
	    	opacity: 0;
	    }

	    &:hover {
	    	background: $green;

	    	&:before {
	    		opacity: 0;
	    	}

	    	&:after {
	    		opacity: 1;
	    	}
	    }
    }

    li {
    	text-align: center;
    	height: 100vh;
    	line-height: 100vh;
    	font-size: 0;

	    img {
	    	max-width: 100vw;
		    max-height: 100vh;
		    width: auto;
		    height: auto;
		    display: inline-block;
		    vertical-align: middle;
		    @include transition(0.5s);
		    opacity: 1;
	    }

	    &:not(.flex-active-slide) img {
	    	opacity: 0;
	    }
	}

	.flex-direction-nav {

		a {
			opacity: 1;
			font-size: 0;
			width: 47px;
		    height: 47px;
		    background: $white;
		    border-radius: 50%;
		    top: inherit;
		    bottom: 30px;

		    &:before,
		    &:after {
		    	content: "";
		    	position: absolute;
		    	top: 0;
		    	bottom: 0;
		    	right: 0;
		    	left: 0;
		    	@include transition(0.3s);
		    }

		    &:before {	    	
		    	background: url(../img/picto_chevron_blue.png) center no-repeat;
		    	background-size: auto 15px;
		    }

		    &:after {	    	
		    	background: url(../img/picto_chevron_white.png) center no-repeat;
		    	background-size: auto 15px;
		    	opacity: 0;
		    }

		    &:hover {
		    	background: $green;

		    	&:before {
		    		opacity: 0;
		    	}

		    	&:after {
		    		opacity: 1;
		    	}
		    }

			&.flex-prev {
				left: 30px;
				@include rotate(180deg);
			}

			&.flex-next {
				right: 30px;
			}
		}
	}
}