#page.node_actualite {

	#section_main {

		.image {
			font-size: 0;
			margin-bottom: 45px;

			&.pointer {
				cursor: pointer;
			}

			img {
				width: 100%;
				height: auto;
			}
		}

		.contenu {

			h1 {
				margin-bottom: 25px;
			}

			.sous_titre {
				color: $green;
				font-weight: 700;
				margin-bottom: 30px;
				font-size: 19px;
				line-height: 1.4;
			}

			.texte {
				margin-bottom: 45px;
			}
		}
	}
}