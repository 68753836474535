#footer {
	background: $blue;
	height: 167px;
	line-height: 167px;
	overflow: hidden;

	@include breakpoint(small down) {
		height: auto;
		line-height: 1;
		padding: 45px 0;	
	}

	.footer_logo {
		@include grid-column(3, $gutters);
		font-size: 0;

		@include breakpoint(small down) {
			@include grid-column(12, $gutters);	
			margin-bottom: 45px;
			text-align: center;		
		}

		img {
			vertical-align: middle;
			height: 50px;
			width: auto;
		}
	}

	.footer_menu {
		@include grid-column(7, $gutters);
		text-align: right;
		font-size: 0;

		@include breakpoint(medium only) {
			@include grid-column(6, $gutters);			
		}

		@include breakpoint(small down) {
			@include grid-column(12, $gutters);	
			margin-bottom: 45px;
			text-align: center;		
		}

		ul li {
			display: inline-block;
			vertical-align: middle;

			&:not(:last-child) {
				margin-right: 48px;
			}

			@include breakpoint(small down) {
				display: block;

				&:not(:last-child) {
					margin-right: 0;
					margin-bottom: 20px;
				}
			}

			a {
				color: $white;
				font-size: 19px;

				&:hover {
					color: $green;
				}
			}
		}
	}

	.footer_social {
		@include grid-column(2, $gutters);
		text-align: right;
		font-size: 0;

		@include breakpoint(medium only) {
			@include grid-column(3, $gutters);			
		}

		@include breakpoint(small down) {
			@include grid-column(12, $gutters);		
			text-align: center;	
		}

		ul li {
			display: inline-block;
			vertical-align: middle;

			&:not(:last-child) {
				margin-right: 38px;
			}

			a {
				display: block;

				&:before {
					font-size: 30px;
					color: $white;
					@include transition(0.3s);
				}

				&:hover:before {
					color: $green;
				}
			}

		}
	}
}