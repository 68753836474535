#page.node_projet {

	#projet_visuel {
		margin-bottom: 45px;

		@include breakpoint(small down) {
			margin-bottom: 30px;
		}

		img {
			width: 100%;
			height: auto;
			cursor: pointer;
		}

		@include breakpoint(small down) {
			height: 250px;				
			position: relative;
			overflow: hidden;

			img {
				height: 250px;
				width: auto;
				position: absolute;
				left: -999px;
				right: -999px;
				z-index: 0;
				margin: auto;
			}
		}
	}

	#projet_video {
		margin-bottom: 45px;

		@include breakpoint(small down) {
			margin-bottom: 30px;
		}
	}

	h1 {
		font-size: 19px;
		letter-spacing: 0.5px;
		margin-bottom: 25px;
	}

	#projet_description {
		margin-bottom: 60px;

		@include breakpoint(small down) {
			margin-bottom: 30px;
		}
	}

	#projet_infos {
		margin-bottom: 30px;

		.block_infos {
			@include grid-column(3, $gutters);
			height: 50px;
			margin-bottom: 15px;
			line-height: 1.4;
			font-size: 13px;

			@include breakpoint(medium down) {
				@include grid-column(6, $gutters);
			}

			@include breakpoint(small down) {
				@include grid-column(12, $gutters);
				height: auto;
			}

			.titre {
				font-weight: 700;
				color: $blackText;
			}

			.sous_titre {
				font-weight: 100;
			}
		}
	}

	#projet_images {

		.image {
			margin-bottom: 50px;
			text-align: center;

			@include breakpoint(small down) {
				margin-bottom: 30px;
			}

			img {
				max-height: 800px;
				max-width: 100%;
				height: auto;
				width: auto;
				cursor: pointer;

				@include breakpoint(medium down) {
					max-height: 600px;
				}

				@include breakpoint(small down) {
					max-height: 300px;
				}
			}
		}
	}

	#projet_videos {

		.embedded-video {
			margin-bottom: 50px;

			@include breakpoint(small down) {
				margin-bottom: 30px;
			}
		}
	}

	#projet_bottom {
		padding-bottom: 50px;

		.book_pdf {
			@include grid-column(6, $gutters);
			float: right;
			text-align: right;

			@include breakpoint(small down) {
				@include grid-column(12, $gutters);
				margin-bottom: 30px;
			}

			a {
				@include downloadButton;
				display: inline-block;
				text-align: left;

				@include breakpoint(small down) {
					width: 100%;
				}
			}
		}

		.to_top_link {
			@include grid-column(6, $gutters);

			@include breakpoint(small down) {
				@include grid-column(12, $gutters);
				text-align: center;
			}

			a {
				color: $blue;
				padding-left: 30px;
				height: 42px;
				line-height: 42px;
				font-size: 19px;
				font-weight: 700;
				letter-spacing: 0.5px;
				text-transform: uppercase;
				position: relative;

				&:before,
				&:after {
					content: "";
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					width: 21px;
					margin: auto;
					@include transition(0.3s);

					@include breakpoint(medium down) {
						height: 30px;
					}
				}

				&:before {
					background: url(../img/chevron_up.png) left 1px center no-repeat;
					background-size: 21px auto;
				}

				&:after {
					background: url(../img/chevron_up_green.png) left 1px center no-repeat;
					background-size: 21px auto;
					opacity: 0;
				}

				&:hover {
					color: $green;

					&:before {
						opacity: 0;
					}

					&:after {
						opacity: 1;
					}
				}
			}
		}
	}

	#projet_video,
	#projet_videos {

		.player {
			padding-bottom: 56.25%;
			position: relative;

			iframe {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
	}
}