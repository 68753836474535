.listing_projets {

	.block_projet {
		@include grid-column(4, $gutters);
		margin-bottom: 37px;

		@include breakpoint(large down) {			
			@include grid-column(6, $gutters);
		}

		@include breakpoint(medium down) {			
			@include grid-column(4, $gutters);
		}

		@include breakpoint(small down) {			
			@include grid-column(12, $gutters);
		}

		&:last-child:not(:first-child) {
			float: left;
		}

		.inner {
			position: relative;

			.image {
				overflow: hidden;
				position: relative;

				img {
					width: 100%;
					height: auto;
				}

				@include breakpoint(small down) {
					height: 191px;
					margin-bottom: 10px;

					img {
						position: absolute;
						top: -999px;
						right: -999px;
						left: -999px;
						bottom: -999px;
						margin: auto;
					}
				}
			}

			.hover {
				position: absolute;
				left: 0;
				bottom: 0;
				right: 0;
				background: rgba(255, 255, 255, 0.9);
				@include transition(0.5s);
				opacity: 0;
				z-index: 2;
				padding-top: 20px;
				padding-left: 15px;
				padding-right: 15px;
				padding-bottom: 25px;
				color: $blue;

				.ref_admin {
					font-size: 12px;
					font-weight: 700;
					margin-bottom: 10px;
				}

				.titre {
					font-size: 16px;
					margin-bottom: 4px;
					color: $black;
				}

				.localisation {
					font-size: 14px;
				}

				.annee {
					position: absolute;
					right: 15px;
					bottom: 25px;
					font-size: 14px;
				}

				@include breakpoint(small down) {
					position: static;
					opacity: 1;
					padding: 0;

					.annee {
						bottom: 0;
					}
				}
			}

			&:before {
				content: "";
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background: $blue;
				z-index: 1;
				opacity: 0;
				@include transition(0.3s);
			}
		}

		@include breakpoint(medium up) {

			&:hover .inner,
			&.hover_triggered .inner {

				.hover {
					opacity: 1;
				}

				&:before {
					opacity: 0.5;
				}
			}
		}
	}
}