.content, #content{

	.messages{
		
		padding: 20px 30px;
		color: #fff;
		font-weight: bold;
		margin-bottom: 30px;
		border: none; 
		position: relative;
		text-align: left;
		font-family: $ffP;

		label{
			color: #fff;
		}

		a{
			color: #000;
			font-weight: bold;

			&:hover{
				color:#fff;
			}
		}


		li{
			margin: 5px 0px;
			font-weight: normal;
			color: #fff;

		}

		> p{
			margin: 5px; 
			color: #fff;
		}

	

		&.status{
			background: $green;
		}

		&.error{
			background: $red;
		}

		&:last-child{
			margin-bottom: 0;
		}

	}

}


#dialogMessage{
	
	#messages{
	
	

		.messages{
			text-align: center;
			font-size: 18px;
			text-transform: uppercase;
			font-family: $ffP; 
			font-size: 20px;
			margin: 0px auto; 
			@include tracking(240);



			h2{
				width: 160px; 
				border-bottom: solid 1px #d2d2d2;
				padding-bottom: 50px;
				overflow: hidden;
				color: #fff;
				display: block;
				margin: 0px auto; 
				margin-bottom: 50px;
				font-size: 0;

				&:after{
					content: "";
					width: 62px; 
					height: 62px;
					-webkit-background-size: 100%; 
					background-size: 100%; 
					display: block;
					margin: 0px auto; 
					background-color: transparent;
					position: relative;
				}
			}

			&.status{
				

				h2:after{
					background-image: url(../img/icon-valid.png);
				}
			}

			&.error{
				

				h2:after{
					background-image: url(../img/icon-error.png);
				}
			}

			&.warning{
				h2:after{
					background-image: url(../img/icon-valid.png);
				}
			}
		}

		ul{
			li{
				font-size: 16px; 
				line-height: 20px; 

				&:not(:last-child){
					margin-bottom: 15px;
				}
			}
		}
	}
}
