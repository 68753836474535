#burger,
#burger_filters {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	z-index:200;
	background: $blue;
	height: 4px;
	width: 30px;
	cursor: pointer;
	@include transition(0.4s);
	bottom: -4px;

	&:before {
		@include transition(0.4s);
		content:'';
		position: absolute;
		right: 0px;
		top: -15px;
		background: $blue;
		height: 4px;
		width: 30px;
	}

	&:after {
		@include transition(0.4s);
		content:none;
		position: absolute;
		right: 0px;
		top: 12px;
		background: $blue;
		height: 4px;
		width: 30px;
	}

	&.opened {
		background: $blue;
	   	z-index: 200;
	   	@include rotate(45deg);
	   	bottom: 3px;

	   	&:before {
	    	background: $blue;
	    	@include rotate(90deg);
	    	top: 0;
	   	}

	   	&:after{
	   		opacity: 0;
	   	}
	}
}	

#header.front {

	#burger,
	#burger:before,
	#burger:after {
		background: $white;
	}
}

#burger_filters {
	background: $green;
	height: 3px;
	width: 21px;

	&:before {
		background: $green;
		height: 3px;
		width: 21px;
		top: -10px;
	}

	&.opened {
		background: $green;

		&:before {
			background: $green;
		}
	}
}