.equipte_listing {

	.equipe_membre {
		
		@include grid-column(2, $gutters);

		@include breakpoint(large down) {
			@include grid-column(3, $gutters);
		}

		@include breakpoint(medium down) {
			@include grid-column(4, $gutters);
		}

		@include breakpoint(small down) {
			@include grid-column(6, $gutters);
		}

		.image {
			position: relative;
			margin-bottom: 20px;

			img {
				width: 100%;
				height: auto;
			}
		}

		.popup_trigger .image::after {
			content:"";
				display: block;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				z-index: 1;
				background: url(../img/play.svg) center no-repeat  rgba(1, 52, 112, 0.7);
				opacity: 0;
		}

		&:hover .image::after {
			opacity: 1;
		}

		.contenu {
			height: 80px;
			line-height: 1.3;

			.nom {
				font-size: 15px;
				color: $blue;
				font-weight: 700;
			}

			.poste {
				font-size: 14px;
				color: $blackText;
			}

			.info {
				font-size: 14px;
				color: $green;
			}
		}
	}
}