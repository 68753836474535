html{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#page_wrapper {
	overflow-x: hidden;
}

* {
  @include box-sizing(border-box);
}
*:before,
*:after {
  @include box-sizing(border-box);
}

/*Transitions*/

a,
input {
	@include transition(0.3s);
	text-decoration: none;

	&:hover,
	&:focus {
		text-decoration: none;
	}
} 

body{
	margin: 0 !important;
	color: $black; 
	font-family: $ffT; 
	background: $bodyBG;

	&.no_scroll{
		overflow: hidden;
	}
	
}

.clear {
	clear: both;
}

.element-invisible{
	display: none; 
}

.image {
	font-size: 0;
}

.no_result {
	font-size: 16px;
	color: $blackText;
	padding-top: 40px;
	text-transform: uppercase;
	text-align: center;
}

a, input{
	outline: none; 
}

h1 {
	text-transform: uppercase;
	font-weight: 700;
	color: $blue;
	font-size: 24px;
	line-height: 1.4;
}

h2 {
	text-transform: uppercase;
	font-size: 23px;
	font-weight: 700;
	color: $blue;
}

h3 {
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 700;
}