body.front {

	#front_banner {
		position: relative;

		&:before {
			content: "";
			position: absolute;
			z-index: 1;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background: $blue;
			opacity: 1;
		}

		.image {

			@include breakpoint(small down) {
				height: 347px;
			}

			img {
				width: 100%;
				height: auto;

				@include breakpoint(small down) {
					height: 347px;
					width: auto;
					position: absolute;
					left: -999px;
					right: -999px;
					z-index: 0;
					margin: auto;
				}
			}
		}

		.banner_inner {
			position: absolute;
			z-index: 2;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;
			height: 125px;
			text-align: center;
			color: $white;

			@include breakpoint(large only) {
				margin-bottom: 80px;
			}

			@include breakpoint(medium down) {
				height: 80px;
			}

			h1 {
				font-size: 68px;
				line-height: 1;
				margin-bottom: 30px;
				color: $white;

				@include breakpoint(large down) {
					font-size: 50px;					
				}

				@include breakpoint(medium down) {
					font-size: 30px;					
				}
			}

			h2 {
				font-size: 29px;
				line-height: 1;
				color: $white;

				@include breakpoint(large down) {
					font-size: 20px;					
				}

				@include breakpoint(medium down) {
					font-size: 14px;					
				}
			}
		}

		#slider_home {
			border: 0;
			margin: 0;

			img {
				//opacity: 0.3;				
			}

			@include breakpoint(small down) {
				
				figure {
					height: 300px;
					position: relative;

					img {
						position: absolute;
						left: -999px;
						right: -999px;
						top: 0;
						height: 100%;
						width: auto;
						margin: auto;
					}
				}
			}

			.flex-direction-nav {

				@include breakpoint(small down) {
					display: none;
				}

				.flex-prev {
					left: 90px;
					@include rotate(180deg);

					@include breakpoint(medium down) {
						left: 45px;
					}
				}

				.flex-next {
					right: 90px;

					@include breakpoint(medium down) {
						right: 45px;
					}
				}

				.flex-prev,
				.flex-next {
					opacity: 1;
					font-size: 0;
					width: 24px;
					height: 46px;
					background: url(../img/chevron_white.png) center no-repeat;
					background-size: 100%;
					top: calc(50% - 13px);
					margin: 0;

					&:before {
						content: none;
					}
				}					
			}

			.flex-control-nav {
				bottom: 52px;
				font-size: 0;
				z-index: 2;

				li {
					margin: 0 7px;

					a {
						background: $white;

						&.flex-active {
							background: $green;
						}
					}
				}
			}
		}
	}

	#section_selection {
		padding-top: 48px;
		padding-bottom: 10px;

		h2 {
			margin-bottom: 48px;
			@include grid-column(12, $gutters);
			float: none;
		}

		.selection_lien a {
			display: block;
			width: 100%;
			height: 381px;
			line-height: 381px;
			text-align: center;
			position: relative;

			@include breakpoint(large down) {
				height: 298px;
				line-height: 298px;
			}

			@include breakpoint(medium down) {
				height: 195px;
				line-height: 195px;
			}

			@include breakpoint(small down) {
				height: 145px;
				line-height: 145px;
			}

			.texte {
				color: $blue;
				text-transform: uppercase;
				font-size: 23px;
				font-weight: 700;
				display: inline-block;
				position: relative;
				padding-left: 35px;
				padding-right: 48px;
				height: 41px;
				line-height: 41px;
				@include transition(0.3s);

				&:before,
				&:after {
					content: "";
					position: absolute;
					top: 0;
					bottom: 0;
					right: 0;
					height: 41px;
					width: 23px;
					@include transition(0.3s);

					@include breakpoint(medium down) {
						height: 30px;
					}
				}

				&:before {
					background: url(../img/chevron_next.png) right center no-repeat;
					background-size: auto 100%;
				}

				&:after {
					background: url(../img/chevron_next_green.png) right center no-repeat;
					background-size: auto 100%;
					opacity: 0;
				}

				@include breakpoint(medium down) {
					font-size: 18px;
					height: 30px;
					line-height: 30px;
				}

				@include breakpoint(small down) {
					font-size: 16px;
					height:	32px;
					line-height: 32px;
				}
			}

			&:hover .texte {
				color: $green;

				&:before {
					opacity: 0;
				}

				&:after {
					opacity: 1;
				}
			}
		}
	}

	#section_agence {

		.home_video_wrapper {
			margin-left: auto;
			margin-right: auto;
			width: 760px;
			margin-bottom: 50px;
		}

		.home_video {
			position: relative;
			padding-bottom: 56.25%;
			width: 100%;

			iframe {
				position: absolute;
				inset: 0;
				width: 100%;
				height: 100%;
			}
		}


		.image {
			font-size: 0;

			img {
				width: 100%;
				height: auto;
			}
		}

		.contenu_agence {
			padding: 50px 0;
			font-size: 0;
			width: calc(100% / 3 * 2);
			margin: auto;

			@include breakpoint(medium down) {
				width: 100%;
			}

			h2 {
				display: inline-block;
				vertical-align: middle;
				width: 50%;
			}

			.voir_plus {
				display: inline-block;
				vertical-align: middle;
				width: 50%;
				text-align: right;

				a {
					font-size: 19px;
					color: $blue; padding-right: 22px;
					height:  20px;
					background: url(../img/chevron_next.png) right center no-repeat;
					background-size: auto 100%;
					display: inline-block;
					height: 20px;
					line-height: 20px;
				}
			}

			.texte {
				margin-top: 38px;
				font-size: 19px;
				color: $blueDark;
				line-height: 1.8;
				letter-spacing: 0.5px;
			}
            .video{
                margin-top: 30px;
                iframe{
                    max-height: 450px;
                    width: 100%;
                    @media (max-width: 768px) {
                        max-height: 330px;
                                                                                                                     
                    }
                                                                                            
                }
                                            
            }
		}
	}
}
