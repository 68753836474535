#page.node_equipe {
	padding-bottom: 170px;

	@include breakpoint(medium down) {
		padding-bottom: 50px;
	}

	.equipe_section {

		h2 {
			margin: 45px 0;
			font-size: 24px;
			line-height: 1.4;
		}

		&:not(:first-child) {

			h2 {
				margin-top: 15px;
			}
		}
	}

	.rejoignez_nous_lien {
		text-align: right;
		padding-top: 30px;

		a {
			font-weight: 700;
			color: $blue;
			font-size: 19px;
			padding-right: 25px;
			display: inline-block;
			height: 30px;
			line-height: 30px;
			position: relative;

			&:before,
			&:after {
				content: "";
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
				width: 15px;
				@include transition(0.3s);
			}

			&:before {
				background: url(../img/arrow_blue.png) right center no-repeat;
				background-size: 15px auto;
			}

			&:after {
				background: url(../img/arrow_green.png) right center no-repeat;
				background-size: 15px auto;
				opacity: 0;
			}

			&:hover {
				color: $green;

				&:before {
					opacity: 0;
				}

				&:after {
					opacity: 1;
				}
			}
		}
	}
}