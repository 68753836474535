#page.node_agence {

	#banner {

		.image {

			@include breakpoint(small down) {
				height: 250px;
				position: relative;
			}

			img {
				width: 100%;
				height: auto;

				@include breakpoint(small down) {
					height: 250px;
				    width: auto;
				    position: absolute;
				    left: -999px;
				    right: -999px;
				    z-index: 0;
				    margin: auto;
				}
			}
		}

		.contenu {
			text-align: center;
			padding: 90px 0;
			max-width: 700px;
			margin: auto;

			h1 {
				margin-bottom: 26px;
			}
		}
	}

	#agence_elements {
		padding-bottom: 52px;

		.bloc_element_page {
			margin-bottom: 48px;

			.element_half {
				@include grid-column(6, $gutters);

				@include breakpoint(medium down) {
					@include grid-column(12, $gutters);
				}

				&.element_image {

					@include breakpoint(medium down) {
						margin-bottom: 30px;
					}

					.image img {
						width: 100%;
						height: auto;
					}
				}

				&.element_texte {
					height: 485px;
					line-height: 485px;
					text-align: center;

					@include breakpoint(large down) {
						height: 380px;
						line-height: 380px;
					}

					@include breakpoint(medium down) {
						height: auto;
						line-height: 1;
					}

					.element_inner {
						max-width: 100%;
						display: inline-block;
						vertical-align: middle;
						line-height: 1;
						text-align: left;

						@include breakpoint(large up) {
							width: 420px;
						}

						h2 {
							font-weight: 24px;
							letter-spacing: 1px;
							margin-bottom: 30px;
						}

						.texte {
							font-weight: 300;
							font-size: 19px;
							color: $blackText;
							line-height: 1.5;
						}
					}
				}
			}

			@include breakpoint(large up) {

				&:nth-child(2n+2) {

					.element_image {
						float: right;
					}

					.element_texte {
						float: left;
					}
				}
			}
		}
	}
}