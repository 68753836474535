.dialog,
.dialog__overlay {
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

.dialog {
	position: fixed;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: center;
	justify-content: center;
	pointer-events: none;
	z-index: 1;
}

.dialog__overlay {
	position: absolute;
	z-index: 1;
	background: rgba(255, 255, 255, 0.7);
	opacity: 0;
	-webkit-transition: opacity 0.3s;
	transition: opacity 0.3s;
	-webkit-backface-visibility: hidden;

	&:before {
		content: "";
		width: 22px;
		height: 22px;
		position: absolute;
		top: 31px;
		right: 45px;
		background: url(../img/close_dialog.png) center no-repeat;
		background-size: 22px 22px;
		cursor: pointer;

		@media only screen and (max-width : $screen-sm-min) {
			right: 5px;
		}

		@media only screen and (max-width : $screen-xs-min) {
			content: none;
		}
	}
}

@media only screen and (max-width : $screen-xs-min) {

	.closeDialog {
		content: "";
		width: 22px;
		height: 22px;
		position: absolute;
		top: 10px;
		right: 10px;
		background: url(../img/close_dialog.png) center no-repeat;
		background-size: 22px 22px;
		cursor: pointer;
	}
}


.dialog--open{
 	.dialog__overlay {
		opacity: 1;
		pointer-events: auto;
	}	
}

.dialog__content {	
	min-width: 290px;
	background: #fff;
	text-align: center;
	position: relative;
	z-index: 1000;
	opacity: 0;
}

.dialog--open .dialog__content {
	pointer-events: auto;
}

@import "dialog-sally.scss"; 

/*
@import "dialog-susan.scss"; 
@import "dialog-sally.scss"; 
@import "dialog-annie.scss"; 
@import "dialog-cathy.scss"; 
@import "dialog-dean.scss"; 
@import "dialog-don.scss"; 
@import "dialog-donna.scss"; 
@import "dialog-henry.scss"; 
@import "dialog-jamie.scss"; 
@import "dialog-jim.scss"; 
@import "dialog-ken.scss"; 
@import "dialog-laura.scss"; 
@import "dialog-ricky.scss"; 
@import "dialog-sally.scss"; 
@import "dialog-sandra.scss"; 
@import "dialog-susan.scss"; 
@import "dialog-val.scss"; 
@import "dialog-wilma.scss";
*/

