.dialog--open{
	z-index: 5555;
}

.dialog__content{
	
	max-height: 90%; 

	@media screen and (max-width: $screen-sm) {	
		max-height: 100%;
		overflow: auto;
	}

	h2{
		
		
	}

	.content{
		
	}

	.scrollable{
		height: 100%; 
		overflow: hidden;
	}

	.dialog_bg{
		overflow: auto;
		max-height: 100%; 
	}
}


#dialogMessage{
	.dialog__content{
		max-width: 40%; 
		.dialog_bg{
			overflow: hidden;
		}
	}
}


#dialogEtudeDownloadList{
	
	.dialog__content{
		padding: 70px 30px 30px 30px;
		width: 100%; 
		text-align: left;
		height: 100%; 
	}
}