/* COULEURS */
$black: 	 #000000;
$white: 	 #ffffff;

$blue: #013470;
$blueDark: #333647;
$blueLight: #9cb2cc;
$green: #cdd039;

$red: #e20025;

$grey: #909090;
$greyLight: #d5d5d5;
$greyBorder: #c0c0c0;
$otherGreyBorder: #e3e3e3;
$blackText: #2e2e2e;
$greyBg: #F5F5F5;

$mainColor: $black; 
$secondColor: $black;

$bodyBG : $white; 

/* COULEURS RESEAUX SOCIAUX */
$colorFacebook : 	#3b5998; 
$colorTwitter : 	#00aced;
$colorInstagram : 	#125688; 
$colorYoutube : 	#bb0000; 
$colorGoogle : 		#dd4b39; 
$colorVimeo : 		#4bf;
$colorLinkedin : 	#0077b5; 
$colorYoutube : 	#dd2c28; 
$colorWeibo : 		#fd8149; 
$colorPinterest: 	#cb2026; 


/* FONT WEIGHT */
$fwThin: 		100;
$fwExtraLight : 200; 
$fwLight : 		300; 
$fwNormal : 	400;               
$fwMedium : 	500;
$fwSemiBold : 	600;
$fwBold : 		700;  
$fwExtraBold : 	800; 
$fwUltraBold : 	900;



/* FONT FAMILY */

$ffT: 'Roboto Condensed', sans-serif;
$ffP: 'Roboto Condensed', sans-serif;
$ffIcon : 'fontawesome';


/* Media queries breakpoints  */
$screen-xs:                  479px;
$screen-xs-min:              $screen-xs;
$screen-phone:               $screen-xs-min;

$screen-sm:                  767px;
$screen-sm-min:              $screen-sm;
$screen-tablet:              $screen-sm-min;

$screen-md:                  991px;
$screen-md-min:              $screen-md;
$screen-desktop:             $screen-md-min;

$screen-lg:                  1199px;
$screen-lg-min:              $screen-lg;
$screen-lg-desktop:          $screen-lg-min;



