#user-login {
	max-width: 500px;
	margin: auto;
	padding: 50px 15px;
	
	.description{
		display: none; 
	}

	.form-item{

		&:not(:last-child){
			margin-bottom: 30px;
		}

		label {			
			margin-bottom: 6px;
			display: block;
		}

		.description, .password-strength-text, .password-strength-title,  div.password-confirm, #edit-timezone{
			display: none !important;  
		}
		
		input[type=text], input[type=email], input[type=password], select, textarea{

			border: solid 1px rgba($black, 0.5);
			padding: 0;
			padding-left: 20px;
			height: 45px;
			appearance: none; 
			font-weight: 300; 
			color: $black; 
			font-size: 18px;
			border-radius: 0;
			width: 100%; 
			background: none; 
			outline: none; 

			@include input-placeholder {
				color: $black; 		
			}

			&:focus{
				border: solid 1px $blue; 

				@include input-placeholder {
					color: $grey;
				}
			}
		}

		textarea{
			padding-top: 15px;
			height: 200px; 
		}
	}

	.form-actions{
		text-align: right;

		.form-submit{
			display: inline-block;
			height: 44px;
			line-height: 44px; 
			text-align: center;
			background: $black; 
			color: #fff; 
			font-size: 16px; 
			font-weight: 600; 
			letter-spacing: 0.8px;
			border: none; 
			padding: 0 30px;
			border: none; 
			max-width: 100%; 
			cursor: pointer; 

			@include breakpoint(small  down){
					width: 100%; 
			}

			&:hover{
				background: $blue; 
			}
		}
	}
}

.webform-client-form {

	> div {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -10px;

		> * {
			padding: 0 10px;

			&:not(:last-child) {
				margin-bottom: 20px;
			}

			&.webform-component-textfield {
				flex: 0 0 50%;
			}

			&.webform-component-email,
			&.webform-component-textarea,
			&.form-actions {
				flex: 0 0 100%;
			}
		}
	}

	label:not(.element-invisible):not(.option) {
		display: block;
		font-size: 15px;
		line-height: 20px;
		margin-bottom: 7px;
	}

	input[type=text],
	input[type=tel],
	input[type=email],
	textarea {
		border: 1px solid #d7d7d7;
		height: 42px;
		font-size: 15px;
		padding-left: 15px;
		background: transparent;
		width: 100%;
		font-family: $ffP;
		@include input-placeholder {
			color: #A0A2A5;
		}

		&:focus {
			outline: 1px solid $blue;
			border-color: $blue;
		}
	}

	input[type=checkbox] {
		position: absolute;
		opacity: 0;
		width: 20px;
		height: 20px;

		&+ label {
			display: block;
			position: relative;
			font-size: 16px;
			line-height: 24px;
			font-weight: 300;
			color: #1E1E1E;
			padding-left: 28px;
			cursor: pointer;

			a {
				color: $blue;
				text-decoration: underline;
				font-weight: 400;
			}

			&:before,
			&:after {
				content: '';
				position: absolute;
				width: 20px;
				height: 20px;
				left: 0;
				top: 0;
				bottom: 0;
				margin: auto;
			}

			&:before {
				border: 1px solid #1E1E1E;
			}

			&:after {
				background: url(../img/check.png) center no-repeat;
				background-size: 16px auto;
			}
		}

		&:not(:checked) + label:after {
			opacity: 0;
		}
	}

	textarea {
		padding-top: 10px;
		padding-right: 15px;
		height: 120px;
		resize: none;
	}

	.form-actions {
		display: flex;
		justify-content: flex-end;

		input[type=submit] {
			@include cta;
			font-family: $ffP;
			cursor: pointer;
		}
	}
}
