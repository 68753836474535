.node_coworking {

	.title2 {
		font-size: 24px;
		line-height: 28px;
		letter-spacing: .1px;
	}

	&__main {
		margin-bottom: 50px;
	}

	&__banner {
		margin-bottom: 90px;

		@include breakpoint(small down) {
			margin-bottom: 30px;
		}

		img {
			max-width: 100%;
			height: auto;
		}
	}

	&__title {
		font-size: 28px;
		line-height: 33px;
		letter-spacing: .5px;
		text-align: center;
		margin: 0 0 30px 0;
	}

	&__description {
		text-align: center;
		max-width: 700px;
		margin: 0 auto 30px auto;
	}

	&__button {
		text-align: center;

		a {
			@include cta;
		}
	}

	&__slider {
		margin: 90px 0 0 0;
		border: 0;

		@include breakpoint(medium up) {
			padding: 0 97px;
		}

		@include breakpoint(small down) {
			margin-top: 60px;
		}

		.flex-direction-nav {

			@include breakpoint(small down) {
				display: none;
			}

			.flex-prev {
				left: 0;
				@include rotate(180deg);
			}

			.flex-next {
				right: 0;
			}

			.flex-prev,
			.flex-next {
				opacity: 1;
				font-size: 0;
				width: 48px;
				height: 48px;
				background: url(../img/chevron_blue.png) center no-repeat;
				background-size: 10px 18px;
				top: calc((100% - 31px) / 2 - 24px);
				margin: 0;

				&:before {
					content: none;
				}
			}					
		}

		.flex-control-nav {
			position: static;
			margin-top: 20px;
			display: flex;
			justify-content: center;

			li {
				margin: 0 8px;

				a {
					display: block;
					background: $otherGreyBorder;
					width: 11px;
					height: 11px;
					box-shadow: none;

					&.flex-active {
						background: $green;
					}
				}
			}
		}

		&:hover .flex-direction-nav {

			.flex-prev {
				left: 0;
			}

			.flex-next {
				right: 0;
			}
		}
	}

	&__equipment {
		background: $greyBg;
		padding-top: 50px;
		padding-bottom: 58px;

		&_title {
			text-align: center;
			margin: 0 0 30px 0;
		}

		&_description {
			max-width: 700px;
			margin: 0 auto 50px auto;
			text-align: center;
		}

		&_list {

			.field-collection-container {
				display: flex;
				flex-wrap: wrap;
				margin: 0 -19px -38px -19px;
				border: 0;
			}

			.field-collection-view {
				flex: 0 0 25%;
				padding: 0 19px;
				margin: 0 0 38px 0;
				border: 0;

				@include breakpoint(medium down) {
					flex: 0 0 calc(100% / 3);
				}

				@include breakpoint(small down) {
					flex: 0 0 50%;
				}

				img {
					display: block;
					max-width: 100%;
					height: auto;
					margin-bottom: 20px;
				}

				.title {
					font-size: 15px;
					line-height: 20px;
					color: $blue;
					text-transform: none;
					margin-bottom: 10px;
				}

				.text {
					font-size: 14px;
					line-height: 28px;
					color: $blackText;
				}
			}
		}
	}

	&__price {
		padding: 50px 0;

		@include breakpoint(small down) {
			padding: 25px 0;
		}

		&_container {

			@include breakpoint(medium up) {
				display: flex;
			}
		}

		&_content {
			position: relative;
			z-index: 2;

			@include breakpoint(medium up) {
				flex: 0 0 579px;
				padding-right: 20px;
			}
		}

		&_title {
			margin-bottom: 30px;
		}

		&_description {
			margin-bottom: 50px;
		}

		&_box {
			background: $blue;
			color: $white;
			padding: 30px;

			@include breakpoint(large up) {
				width: calc(100% + 97px);				
			}
		}

		&_subtitle {
			font-size: 18px;
			line-height: 20px;
			font-weight: 700;
			margin-bottom: 20px;
		}

		&_amount {
			font-size: 24px;
			line-height: 28px;
			color: $green;
			font-weight: 700;
			margin-bottom: 20px;
		}

		&_list {
			margin-bottom: 30px;

			li {
				position: relative;
				padding-left: 35px;
				font-size: 15px;
				line-height: 28px;
				background: url(../img/check_green.png) left top 4px no-repeat;
				background-size: 20px auto;

				&:not(:last-child) {
					margin-bottom: 15px;
				}
			}
		}

		&_link a {
			display: inline-block;
			font-size: 18px;
			line-height: 20px;
			color: $white;
			font-weight: 500;
			padding-right: 28px;
			background: url(../img/arrow_white.svg) right top no-repeat;
			background-size: 20px auto;

			&:hover {
				text-decoration: underline;
			}
		}

		&_image {
			position: relative;
			z-index: 1;
			
			@include breakpoint(medium up) {
				flex: 1;
			}

			@include breakpoint(small down) {
				margin-top: 20px;

				img {
					max-width: 100%;
					height: auto;
				}
			}
		}
	}

	&__contact {
		padding: 50px 0;

		@include breakpoint(small down) {
			padding: 25px 0;
		}

		&_container {

			@include breakpoint(medium up) {
				display: flex;
			}
		}

		&_map {

			@include breakpoint(medium up) {
				flex: 1;
			}

			@include breakpoint(small down) {
				margin-bottom: 20px;
			}

			iframe {
				width: 100%;
				height: 414px;
			}
		}

		&_title {
			margin-bottom: 30px;
		}

		&_subtitle {
			font-size: 18px;
			line-height: 20px;
			color: $blue;
			font-weight: 700;
			padding-bottom: 8px;
			position: relative;
			margin-bottom: 20px;

			&:after {
				content: "";
				width: 40px;
				height: 3px;
				background: $green;
				position: absolute;
				bottom: 0;
				left: 0;
			}
		}

		&_content {

			@include breakpoint(medium up) {
				flex: 0 0 484px;
				padding-left: 20px;
			}

			@include breakpoint(medium only) {
				flex: 0 0 300px;
			}
		}
	}

	&__message {
		background: $greyBg;
		padding: 50px 0;

		&_title {
			text-align: center;
			margin: 0 0 30px 0;
		}

		&_description {
			max-width: 700px;
			margin: 0 auto 50px auto;
			text-align: center;
		}

		form {
			max-width: 560px;
			margin: auto;
		}
	}
}