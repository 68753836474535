.listing_annonces {

	.annonce_block {
		@include grid-column(6, $gutters);
		margin-bottom: 50px;

		@include breakpoint(medium down) {			
			@include grid-column(12, $gutters);
		}

		&:last-child:not(:first-child) {
			float: left;
		}

		&:nth-child(2n+1) {
			clear: left;
		}

		.titre {
			margin-bottom: 5px;
			color: $blue;
			text-transform: uppercase;
			font-weight: 700;
			font-size: 19px;
		}

		.sous_titre {
			margin-bottom: 40px;
			font-weight: 700;
			font-size: 19px;
			color: $green;
		}

		.texte_annonce {
			margin-bottom: 20px;
		}

		.texte_experience {
			margin-bottom: 45px;
		}

		.lien {

			a {
				display: inline-block;
				height: 42px;
				line-height: 40px;
				border: 1px solid $blue;
				padding: 0 28px;
				font-size: 15px;
				color: $blue;

				&:hover {
					color: $white;
					background: $blue;
				}
			}
		}
	}
}