.selection-list {

	.selection_block {
		@include grid-column(6, $gutters);
		margin-bottom: 35px;

		@include breakpoint(small down) {
			@include grid-column(12, $gutters);
			float: none;
			max-width: 555px;
			margin: auto;
			margin-bottom: 30px;
		}

		&:last-child:not(:first-child) {
			float: left;
		}

		.listing_inner {

			.image_wrap {
				margin-bottom: 15px;
			}

			.titre {
				color: $black;

				@include breakpoint(large up) {
					height: 84px;
					font-size: 16px;
					letter-spacing: 0.68px;
					line-height: 20px;

					&:before {
						top: 3px;
					}
				}

				@include breakpoint(medium only) {
					overflow: hidden;
					height: 45px;
				}
			}
		}
	}

	.selection_lien {
		@include grid-column(6, $gutters);

		@include breakpoint(small down) {
			@include grid-column(12, $gutters);
			float: none;
		}
	}
}