.rte {
	
	p {
		font-weight: 300;
		font-size: 19px;
		color: $blackText;
		line-height: 1.5;

		@include breakpoint(small down) {
			font-size: 16px;
		}
		
		strong {
			font-weight: 700;
		}

		&:not(:last-child) {
			margin-bottom: 35px;
		}

		a {			
			color: $blue;
		}

		&.buttonlink a {
			font-size: 15px;
			padding: 10px 20px;
			border: 1px solid $blue;
			color: $blue;
			font-weight: 400;

			&:hover {
				background: $blue;
				color: $white;
			}
		}
	}

	ul {
		font-weight: 300;
		font-size: 19px;
		color: $blackText;
		line-height: 1.5;

		@include breakpoint(small down) {
			font-size: 16px;
		}
		
		strong {
			font-weight: 700;
		}

		&:not(:last-child) {
			margin-bottom: 35px;
		}

		li {

		}
	}

	h2 {

	}

	h3 {
		
	}

	blockquote {
		
	}

	&.texte_annonce {

		p,
		li {
			font-weight: 300;
			font-size: 13px;
			color: $blackText;
		}

		p:not(:last-child),
		ul:not(:last-child) {
			margin-bottom: 20px;
		}

		ul li {
			text-indent: 8px;
			position: relative;

			&:before {
				content: "";
				width: 3px;
				height: 3px;
				border-radius: 50%;
				background: $blackText;
				position: absolute;
				left: 0;
				top: 8px;
			}
		}
	}

	&.texte_experience {

		p,
		li {
			font-weight: 700;
			font-size: 13px;
			color: $blue;
		}

		p:not(:last-child),
		ul:not(:last-child) {
			margin-bottom: 0;
		}
	}

	&.no_margins {

		p,
		ul,
		ol {

			&:not(:last-child) {
				margin-bottom: 0;
			}
		}
	}

	&.small {
		
		p,
		li {
			font-size: 15px;
			line-height: 28px;
		}

		ul li {
			padding-left: 22px;
			position: relative;

			&:before {
				content: "";
				width: 3px;
				height: 3px;
				border-radius: 50%;
				background: $blackText;
				position: absolute;
				left: 8px;
				top: 13px;
			}
		}
	}
}
