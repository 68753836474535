#node_top {
	height: 50px;
	line-height: 50px;
	font-size: 19px;
	font-weight: 700;
	letter-spacing: 0.5px;
	text-transform: uppercase;

	@include breakpoint(small down) {
		font-size: 16px;
	}

	.back_link {
		@include grid-column(6, $gutters);

		@include breakpoint(small down) {
			@include grid-column(4, $gutters);
		}

		a {
			color: $blue;
			padding-left: 25px;
			position: relative;

			&:before,
			&:after {
				content: "";
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				height: 20px;
				width: 11px;
				margin: auto;
				@include transition(0.3s);

				@include breakpoint(medium down) {
					height: 30px;
				}
			}

			&:before {
				background: url(../img/chevron_back.png) left 1px center no-repeat;
				background-size: 11px auto;
			}

			&:after {
				background: url(../img/chevron_back_green.png) left 1px center no-repeat;
				background-size: 11px auto;
				opacity: 0;
			}

			&:hover {
				color: $green;

				&:before {
					opacity: 0;
				}

				&:after {
					opacity: 1;
				}
			}
		}
	}

	.categorie,
	.date {
		@include grid-column(6, $gutters);
		text-align: right;
		color: $green;

		@include breakpoint(small down) {
			@include grid-column(8, $gutters);
		}
	}
}