.popup_trigger {
	cursor: pointer;
}

.custom_popup {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	@include transition(0.3s);
	pointer-events: none;
	text-align: center;
	line-height: 100vh;
	z-index: 9999;
	overflow: auto;	
	opacity: 0;

	&.active {
		opacity: 1;
		pointer-events: all;
	}

	.popup_overlay {
  		background: rgba(1, 52, 112, 0.3);
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 0;
	}

	.popup_inner {
		line-height: 1;
		vertical-align: middle;
		display: inline-block;
		position: relative;
		max-width: 90%;
		width: 750px;
		max-height: 100vh;
		overflow-x: auto;

		.iframe_container {
			position: relative;
			width: 100%;
			height: 0;
			padding-bottom: 51%;

			iframe {
				position: absolute;
			    width: 100%;
			    height: 100%;
			    left: 0; top: 0;
			}
		}

		.video_container {

			video {
				width: 100%;
				height: auto;
			}
		}

		.popup_top {
			text-align: right;
			margin-bottom: 25px;
		}

		.popup_bottom {
			text-align: center;
			margin-top: 25px;
		}

		.close_popup {
			cursor: pointer;

			&.croix {
				display: inline-block;
				width: 15px;
				height: 15px;
				background: url(../img/close.png) center no-repeat;
				background-size: 100% 100%;
				position: absolute;
				top: 20px;
				right: 20px;

				@include breakpoint(small down) {
					top: 10px;
					right: 10px;					
				}
			}
		}
	}

	&.whitebg {

		.popup_inner {
			background: $white;
			padding: 25px;
			width: 600px;

			@include breakpoint(small down) {
				padding-left: 15px;
				padding-right: 15px;
				padding-bottom: 15px;
			}

			.popup_top {
				margin-bottom: 0;
				height: 0;
			}

			h2 {
				font-size: 19px;
				margin-bottom: 45px;
				text-transform: uppercase;
				letter-spacing: 0.5px;
				text-align: center;
			}
		}

		&#popup_book {

			.popup_inner {
				width: 810px;
				padding: 45px 30px;
				text-align: left;
				font-size: 0;

				@include breakpoint(small down) {
					padding-right: 15px;
					padding-left: 15px;
				}

				.download_pdf_book {
					display: inline-block;
					vertical-align: middle;
					margin-bottom: 25px;
					font-size: 14px;
					width: 165px;

					&:nth-child(5) {
						width: 150px;
					}

					&:nth-child(7) {
						width: 180px;
					}

					&:not(:nth-child(4n+4)) {						
						margin-right: 25px;
					}

					@include breakpoint(medium down) {
						width: calc((100% - 25px) / 2);

						&:nth-child(5),
						&:nth-child(7) {
							width: calc((100% - 25px) / 2);
						}

						&:not(:nth-child(4n+4)) {						
							margin-right: 0;
						}

						&:nth-child(2n+1) {						
							margin-right: 25px;
						}
					}

					a {
						@include downloadButton;
						padding-left: 15px;
						padding-right: 30px;

						&:before,
						&:after {
							right: 10px;
						}
					}

					@include breakpoint(small down) {
						display: block;
						margin-right: 0;
						margin-bottom: 10px;
						width: 100%;

						&:nth-child(5),
						&:nth-child(7) {
							width: 100%;
						}

						&:nth-child(2n+1) {						
							margin-right: 0;
						}
					}
				}
			}
		}

		&#messages {

			.popup_inner {
				width: auto;

				.popup_top {
					display: none;
				}

				.messages {
					margin-bottom: 40px;

					p {
						font-size: 16px;
						line-height: 19px;
					}
				}
			}
		}
	}
}

#popup_video {
	.popup_inner {
		width: 760px;
		padding: 48px;

		@include breakpoint(small down) {
			padding: 32px;
		}
	}

	.popup_embed_video iframe {
		width: 100%;
	}

	.popup_overlay {
		background: rgba(1, 52, 112, 0.7);
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 0;
  }
}