.block_listing {

	.listing_inner {

		.image_wrap {
			position: relative;
			font-size: 0;
			margin-bottom: 15px;

			img {
				width: 100%;
				height: auto;
			}

			.hover {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				@include transition(0.3s);
				opacity: 0;

				.hover_bg {
					background: $blue;
					opacity: 0.5;					
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					z-index: 1;
				}

				.hover_texte {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					height: 40px;
					line-height: 40px;
					margin: auto;
					color: $white;
					text-transform: uppercase;
					font-size: 22px;
					text-align: center;
					font-weight: 700;
					z-index: 2;
					letter-spacing: 1px;

					@include breakpoint(medium down) {
						font-size: 16px;
					}
				}
			}
		}

		.titre {
			font-size: 19px;
			color: $blue;
			text-transform: uppercase;
			font-weight: 700;
			letter-spacing: 1px;
			line-height: 1.1;
			position: relative;

			@include breakpoint(medium down) {
				font-size: 14px;
			}

			&:before {
				content: "";
				position: absolute;
				width: 5px;
				height: 48px;
				top: -3px;
				left: -15px;
				@include transition(0.3s);
			}
		}

		&:hover {

			.image_wrap .hover {
				opacity: 1;
			}

			.titre:before {
				background: $green;
			}
		}
	}
}